<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getPartner } from '@/api/Partners.js'
  import { Partner } from "@/models/Partner";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Parceiro",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "Parceiro",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Parceiro",
            active: true,
          },
        ],
        partner: new Partner()
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        
        getPartner().then(response => {
          vm.partner = new Partner(response)
        })
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
  
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">
          Editar Parceiro
        </h4>
      
        <form
          class="form-horizontal"
          role="form"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Nome Fantasia"
            label-for="name"
          >
            <b-form-input
              for="name"
              v-model="partner.name"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Razão Social"
            label-for="business_name"
          >
            <b-form-input
              for="business_name"
              v-model="partner.business_name"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="CPF/CNPJ"
            label-for="nif"
          >
            <b-form-input
              for="nif"
              v-model="partner.nif"
            />
          </b-form-group>
        
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="E-mail"
            label-for="email"
          >
            <b-form-input
              for="email"
              v-model="partner.email"
              :disabled="true"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Telefone"
            label-for="phone"
          >
            <b-form-input
              for="phone"
              v-model="partner.phone"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Logradouro"
            label-for="street"
          >
            <b-form-input
              for="street"
              v-model="partner.street"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Número"
            label-for="number"
          >
            <b-form-input
              for="number"
              v-model="partner.number"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Complemento"
            label-for="complement"
          >
            <b-form-input
              for="complement"
              v-model="partner.complement"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Bairro"
            label-for="district"
          >
            <b-form-input
              for="district"
              v-model="partner.district"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Código Postal"
            label-for="zipcode"
          >
            <b-form-input
              for="zipcode"
              v-model="partner.zipcode"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="Cidade"
            label-for="city"
          >
            <b-form-input
              for="city"
              v-model="partner.city"
            />
          </b-form-group>
  
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="3"
            label="UF"
            label-for="state"
          >
            <b-form-input
              for="state"
              v-model="partner.state"
            />
          </b-form-group>
        
          <b-button variant="primary" style="float: right">
            Salvar
          </b-button>
        </form>
      </div>
    </div>
  </Layout>
</template>

<style>
  .table .unreaded td{
    font-weight: 500 !important;
  }

  .table .unreaded th{
    font-weight: 500 !important;
  }
</style>
